<template>
  <div>
      <b-form-input v-model="content.title"></b-form-input>
      <ckeditor v-model="content.content"></ckeditor>
      <b-button class="my-3" size="sm" variant="success" @click="$emit('save-page')">Salvează</b-button>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'
Vue.use(CKEditor)
export default {
  name: 'DocumentationEdit',
  data () {
    return {
    }
  },
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {

    slug: function () {
      var slug = this.sanitizeTitle(this.content.title)
      return slug
    }
  },
  watch: {
    slug: function (slug) {
      this.content.href = slug
    }
  },
  mounted () {
  },
  methods: {
    sanitizeTitle: function () {
      var slug = ''
      // Change to lower case
      var titleLower = this.content.title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')
      return slug
    }
  }
}
</script>

<template>
  <div class="container-fluid pt-3">
    <b-button to="/discount/0" class="mb-3">Add new</b-button>
    <b-table
      :items="items"
      :fields="fields"
    >
      <template #cell(date_from)="row">
        {{ dateFormat(row.item.date_from) }}
      </template>
      <template #cell(date_to)="row">
        {{ dateFormat(row.item.date_to) }}
      </template>
      <template #cell(actions)="row">
        <b-button :to="'/discount/' + row.item.id" size="sm" variant="info">Edit</b-button>
        <b-button @click="deleteDiscount(row.item.id)" size="sm" variant="danger" class="ml-3">Delete</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>

import config from '@/config'

export default {
  name: 'DiscountsList',
  created () {
    this.$store.dispatch('discounts/fetchDiscounts')
  },
  data () {
    return {
      fields: [
        { key: 'budget', label: 'Budget' },
        { key: 'budget_spent', label: 'Budget utilizat' },
        { key: 'date_from', label: 'Start' },
        { key: 'date_to', label: 'End' },
        { key: 'title', label: 'Titlu' },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },
  computed: {
    items () {
      return this.$store.state.discounts.list
    }
  },
  methods: {
    dateFormat (time) {
      return this.$day(time).format('DD MMM YYYY')
    },
    deleteDiscount (id) {
      if (confirm('Remove this discount?')) {
        this.$axios.delete(config.baseApiUrl + '/api/discounts/' + id)
          .then(() => {
            this.$store.dispatch('discounts/fetchDiscounts')
            this.$toasted.error('Removed', {
              duration: 3000
            })
          })
          .catch(() => {
            this.$toasted.error('Can not remove. Discount is already in use!', {
              duration: 3000
            })
          })
      }
    }
  }
}
</script>

<style scoped>

</style>

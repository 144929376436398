<template>
  <div>
      <h2 :id="'list-item-' + idx">{{ content.title }}</h2>
      <div v-html="content.content"></div>
  </div>
</template>

<script>

export default {
  name: 'DocumentationBlockView',
  data () {
    return {
    }
  },
  props: {
    idx: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col md="3">
          <ul class="documentation-menu">
            <li v-for="(item, idx) in items" :key="idx" v-b-toggle="'collapse-' + idx">
                <div class="d-flex justify-content-between align-items-center">
                  <b-link :to="'/documentation/' + item.url">{{ item.title }} </b-link>
                  <b-link class="ml-auto text-danger" style="font-size:12px" @click="remove(item.id)">șterge</b-link>
                  <b-icon  icon="chevron-down"></b-icon>
                </div>
                <b-collapse :id="'collapse-' + idx">
                  <ul v-if="item.childs.length">
                      <li v-for="(child, idx) in item.childs" :key="idx">
                        <b-link :to="'/documentation/' + child.url">{{ child.title }} </b-link>
                        <b-link class="text-danger" style="font-size:12px" @click="remove(child.id)">șterge</b-link>
                      </li>
                  </ul>
                </b-collapse>
            </li>
            <li>
              <b-input-group size="sm">
                <b-form-input v-model="documentation.title" ></b-form-input>
                <b-input-group-append>
                  <b-button @click="create">Add</b-button>
                </b-input-group-append>
              </b-input-group>
            </li>
          </ul>
        </b-col>
        <b-col md="9">
          <DocumentationView :page-object="page" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'
import DocumentationView from '@/documentation/control/DocumentationView'

export default {
  name: 'Documentation',
  components: {
    DocumentationView
  },
  data () {
    return {
      page: null,
      items: [],
      documentation: {
        title: '',
        url: null,
        content: []
      }
    }
  },
  computed: {
    slug: function () {
      var slug = this.sanitizeTitle(this.documentation.title)
      return slug
    }
  },
  watch: {
    slug: function (slug) {
      this.documentation.url = slug
    },
    '$route.params': {
      handler (newValue) {
        const { url } = newValue

        this.getByUrl(url)
      },
      immediate: true
    }
  },
  mounted () {
    this.get()
    this.getByUrl()
  },
  methods: {
    sanitizeTitle: function () {
      var slug = ''
      // Change to lower case
      var titleLower = this.documentation.title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')
      return slug
    },
    getByUrl () {
      this.$axios.get(config.baseApiUrl + '/api/docs/url/' + this.$route.params.url)
        .then((response) => {
          this.page = response.data[0]
        })
    },
    get () {
      this.$axios.get(config.baseApiUrl + '/api/docs')
        .then((response) => {
          this.items = response.data
        })
    },
    remove (id) {
      this.$axios.delete(config.baseApiUrl + '/api/docs/' + id)
        .then((response) => {
          this.get()
        })
    },
    create () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/docs',
        data: this.documentation
      })
        .then((response) => {
          this.get()
          this.documentation.title = ''
        })
    }
  }
}
</script>

<template>
  <div class="py-3">
    <b-container fluid>
      <b-row>
        <b-col md="12" id="listgroup-ex">
          <h1 >{{ pageObject.title }} <b-button v-b-toggle="'collapse-new'" variant="outline-success" size="sm"> <b-icon icon="plus"></b-icon> </b-button></h1>
          <b-collapse id="collapse-new">
            <b-card>
              <b-form-input v-model="content.title" placeholder="Titlul secțiunii"></b-form-input>
              <ckeditor v-model="content.content"></ckeditor>
              <b-button class="mt-3" variant="success" size="sm" @click="addContent">Salvează</b-button>
            </b-card>
          </b-collapse>
          <div class="my-5 block" v-for="(item, idx) in pageObject.content" :key="idx">
            <div class="block-edit">
              <b-link @click="edit(idx)">Edit</b-link>
              <b-link  @click="remove(idx)">Remove</b-link>
            </div>
            <DocumentationEdit @save-page="savePage" :content="pageObject.content[idx]" v-if="inEdit === idx" />
            <DocumentationBlockView :idx="idx" :content="pageObject.content[idx]"  v-if="inEdit !== idx" />
          </div>
        </b-col>
        <b-col md="4" class="d-none">
          <b-list-group class="block-right" v-b-scrollspy:listgroup-ex>
            <b-list-group-item v-for="(item, idx) in pageObject.content" :key="idx" :href="'#list-item-' + idx">{{ item.title }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'
import DocumentationEdit from '@/documentation/control/DocumentationEdit'
import DocumentationBlockView from '@/documentation/control/DocumentationBlockView'
import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'
Vue.use(CKEditor)

export default {
  name: 'DocumentationView',
  components: {
    DocumentationEdit,
    DocumentationBlockView
  },
  data () {
    return {
      inEdit: null,
      content: {
        title: null,
        content: null,
        href: null
      }
    }
  },
  props: {
    pageObject: {
      type: Object,
      required: true,
      default: null
    }
  },
  mounted () {
    // this.getPage()
  },
  methods: {
    remove (idx) {
      this.pageObject.content.splice(idx, 1)
      this.savePage()
    },
    edit (idx) {
      if (idx === this.inEdit) {
        this.inEdit = null
      } else {
        this.inEdit = idx
      }
    },
    getPage () {
      this.$axios.get(config.baseApiUrl + '/api/docs/url/' + this.$route.params.url)
        .then((response) => {
          this.content = response.data
        })
    },
    savePage () {
      this.$axios({
        method: 'patch',
        url: config.baseApiUrl + '/api/docs/' + this.pageObject.id,
        data: this.pageObject
      })
        .then((response) => {
          this.$toasted.success('Salvat cu succes!', {
            duration: 3000
          })
          this.edit()
        })
    },
    addContent () {
      this.pageObject.content.push(Object.assign({}, this.content))
      this.savePage()
      this.content = {
        title: null,
        content: null,
        href: null
      }
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid>
      <h1 style="font-size:26px">{{ page.title }}</h1>
      <div v-html="page.content"></div>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'Page',
  data () {
    return {
      page: {
        title: ''
      }
    }
  },
  mounted () {
    this.getPage()
  },
  methods: {
    getPage () {
      this.$axios.get(config.baseApiUrl + '/api/content/url/' + this.$route.params.url)
        .then((response) => {
          this.page = response.data[0]
          document.title = this.page.title
        })
    }
  }
}
</script>

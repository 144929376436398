<template>
  <div class="container-fluid">
    <br>
    <b-form v-if="editable" @submit.prevent="save">
      <div class="row">
        <div class="col-md-8">
          <b-form-group
            label="Budget"
            label-for="discount-budget"
          >
            <b-form-input
              id="discount-budget"
              v-model.number="editable.budget"
              type="number"
              step="0.01"
              placeholder="Budget"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Budget spent"
            label-for="discount-budget-spent"
          >
            <b-form-input
              id="discount-budget-spent"
              v-model="editable.budget_spent"
              placeholder="Budget spent"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="De la"
            label-for="discount-date_from"
          >
            <b-form-datepicker
              id="discount-date_from"
              v-model="editable.date_from"
              placeholder="De la"
              required
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group
            label="Pana la"
            label-for="discount-date_to"
          >
            <b-form-datepicker
              id="discount-date_to"
              v-model="editable.date_to"
              placeholder="Pana la"
              required
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group
            label="Application type"
            label-for="discount-application_type"
          >
            <b-form-select
              id="discount-application_type"
              v-model.number="editable.application_type"
              placeholder="Application type"
              required
            >
              <b-form-select-option value="all_users">All users</b-form-select-option>
              <b-form-select-option value="new_users">New users</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Titlu"
            label-for="discount-title"
          >
            <b-form-input
              id="discount-title"
              v-model="editable.title"
              placeholder="Titlu"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Descriere"
            label-for="discount-description"
          >
            <ckeditor
              id="discount-description"
              v-model="editable.description"
              placeholder="Descriere"
            ></ckeditor>
          </b-form-group>
          <b-form-group
            label="Dispeceriat"
            label-for="discount-company_id"
          >
            <CompanySelect v-model="editable.company_id" :multiple="false"></CompanySelect>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-list-group>
            <b-list-group-item v-for="(condition, index) in editable.conditions" :key="'ci-' + index">
              <b-row>
                <b-col>
                  <b-form-group
                    label="Discount type"
                    :label-for="'discount-condition-discount_type-' + index"
                  >
                    <b-form-select
                      :id="'discount-condition-discount_type-' + index"
                      v-model.number="condition.discount_type"
                      placeholder="Value"
                      required
                    >
                      <b-form-select-option value="percent">Percent</b-form-select-option>
                      <b-form-select-option value="amount">Fixed amount</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Value"
                    :label-for="'discount-condition-value-' + index"
                  >
                    <b-form-input
                      :id="'discount-condition-value-' + index"
                      v-model.number="condition.value"
                      placeholder="Value"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Max discount"
                    :label-for="'discount-condition-max_discount-' + index"
                  >
                    <b-form-input
                      :id="'discount-condition-max_discount-' + index"
                      v-model.number="condition.max_discount"
                      placeholder="Max discount"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col style="max-width: 105px;">
                  <div style="margin-bottom: 30px;"></div>
                  <b-button v-if="index === editable.conditions.length - 1" @click="removeCondition(index)" size="sm" variant="danger">Remove</b-button>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <div class="text-center">
            <b-button @click="addCondition" class="mt-2">Add new condition</b-button>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <b-button type="submit" variant="success" size="lg">SAVE</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'
import config from '@/config'
import CompanySelect from '@/components/common/CompanySelect'
Vue.use(CKEditor)

export default {
  name: 'DiscountEdit',
  components: { CompanySelect },
  data () {
    return {
      discountScratch: {
        budget: 0,
        budget_spent: 0,
        date_from: null,
        date_to: null,
        title: '',
        description: '',
        application_type: null,
        conditions: [],
        company_id: null
      },
      conditionScratch: {
        discount_type: null,
        value: 0
      },
      editable: null
    }
  },
  created () {
    if (this.$store.state.discounts.list === null) {
      this.$router.push('/discounts')
      return
    }
    if (this.id > 0) {
      this.editable = this.$store.getters['discounts/getById'](this.id)
    } else {
      this.editable = Object.assign({}, this.discountScratch)
    }
  },
  computed: {
    id () {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    save () {
      const method = this.id ? 'patch' : 'post'
      const url = config.baseApiUrl + '/api/discounts' + (this.id ? ('/' + this.id) : '')
      this.$axios[method](url, this.editable)
        .then((response) => {
          if (method === 'post') {
            this.$router.push('/discount/' + response.data.id)
          }
          this.editable = response.data
          this.$toasted.success('Saved', {
            duration: 2000
          })
        })
        .catch((error) => {
          Object.entries(error.response.data.errors).forEach(([key, group]) => {
            group.forEach((message) => {
              this.$toasted.error(message, {
                duration: 5000
              })
            })
          })
        })
    },
    addCondition () {
      this.editable.conditions.push(Object.assign({}, this.conditionScratch))
    },
    removeCondition (index) {
      this.editable.conditions.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
